import React, { useCallback } from 'react'

import Layout from 'components/layout'
import HeroComponent from 'components/hero'
import Seo from 'components/seo'
import ContentMain from 'components/contentMain'

import { graphql } from 'gatsby'

const PoliticasCalidadPage = ({ data, location }) => {
  const {
    sanityAppereance,
    sanityCalidadPage: {
      tabsCalidad: { seo, Call_to_Action, Hero, contentMainsection },
    },
  } = data

  const setCallToActionBtn = useCallback(() => {
    console.log('click btn contáctanos')
  }, [])

  return (
    <Layout
      header={sanityAppereance?.tabsAppereance?.header}
      footer={sanityAppereance?.tabsAppereance?.footer}
    >
      {seo?.seo?.focus_keyword && seo?.author && seo?.seo?.focus_synonyms && (
        <Seo data={seo} />
      )}
      {Hero?.enable && (
        <HeroComponent
          navbar={Hero?.logoEmpresaHeader?.image?.asset?.url}
          navbarAlt={Hero?.logoEmpresaHeader?.alt}
          imageBackground={Hero?.image.image.asset.url}
          title={Hero?.title}
          subTitle={Hero?.description}
          btnText={Hero?.cta?.text}
          btnCallToAction={setCallToActionBtn}
          btnEnable={Hero?.cta?.enable}
          btnLink={Hero?.cta?.link}
          location={location.pathname}
        />
      )}
      <ContentMain
        rawPublicacion={contentMainsection?._rawPublicacion}
        publicacion={contentMainsection?.publicacion}
        callToAction={Call_to_Action}
      />
    </Layout>
  )
}

export const CalidadQuery = graphql`
  query CalidadQuery {
    sanityAppereance(slug: { current: { eq: "apariencia" } }) {
      tabsAppereance {
        footer {
          footerTitle
          footerParagrah
          enableFooter
          direccion
          departamento
          correo3
          correo2
          correo1
          logoEmpresaFooter {
            alt
            image {
              asset {
                url
              }
            }
          }
        }
        header {
          correo
          enableHeader
          linkFacebook
          linkInstagram
          linkLinkedin
          linkTwitter
          messenger
          telefono
        }
      }
    }
    sanityCalidadPage(slug: { current: { eq: "politicas" } }) {
      tabsCalidad {
        seo {
          author
          _key
          seo {
            seo_title
            meta_description
            focus_synonyms
            focus_keyword
            _type
            _key
          }
          ogImage {
            asset {
              url
            }
          }
        }
        Call_to_Action {
          title
          enable
          description
          cta {
            text
            link
            enable
          }
        }
        Hero {
          title
          enable
          description
          cta {
            text
            link
            enable
          }
          image {
            image {
              asset {
                url
              }
            }
          }
          logoEmpresaHeader {
            image {
              asset {
                url
              }
            }
            alt
          }
        }
        contentMainsection {
          enable
          _rawPublicacion
          publicacion {
            _rawDescription
            _rawGaleria
            title
            galeria {
              enable
              images {
                alt
                image {
                  asset {
                    url
                  }
                }
                _rawImage
              }
            }
          }
        }
      }
    }
  }
`

export default PoliticasCalidadPage
