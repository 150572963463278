import React, { useEffect, useRef, useState } from 'react'

const GrupoImagenesPrincipal = ({ rawGaleria, rawImages, objetoImagenes }) => {
  const [width, setWidth] = useState(null)
  const ref = useRef(null)
  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0)
  }, [ref.current])

  useEffect(() => {
    console.log('publicacion', rawGaleria)
  })

  const ImgComponent = ({ imgGroup, alt }) => (
    <div
      className="groupImages-images--img shadow"
      alt={alt}
      style={{
        backgroundImage: `url(${imgGroup})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: width,
      }}
      ref={ref}
    ></div>
  )

  return (
    <div className="container">
      {rawGaleria?.enable && (
        <div className="groupImages d-flex flex-wrap col-12 my-4">
          {rawImages.map(({ alt }, key) => (
            <div className="groupImages-images col-6 col-lg-4 px-3">
              {objetoImagenes?.enable && (
                <ImgComponent
                  imgGroup={objetoImagenes?.images[key]?.image?.asset?.url}
                  alt={alt}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default GrupoImagenesPrincipal
