import React from 'react'
import CallToAction from 'components/callToAction'
import BlockText from 'components/ui/block-text'
import GrupoImagenesPrincipal from 'components/componenteImagenes/grupoImagenes'

const ContentMain = ({ rawPublicacion, publicacion, callToAction }) => {
  const renderContactUsSection = (size, key) => {
    if (Math.ceil(size / 2) == key) {
      return (
        <CallToAction
          title={callToAction?.title}
          paragraph={callToAction?.description}
          btnText={callToAction?.cta.text}
          btnEnable={callToAction?.cta.enable}
          btnLink={callToAction?.cta.link}
        />
      )
    }
  }
  const renderCta = size => {
    if (size === 1) {
      return (
        <CallToAction
          title={callToAction?.title}
          paragraph={callToAction?.description}
          btnText={callToAction?.cta.text}
          btnEnable={callToAction?.cta.enable}
          btnLink={callToAction?.cta.link}
        />
      )
    }
  }

  return (
    <div className="nosotros">
      {rawPublicacion.map(({ title, galeria }, key) => {
        return (
          <>
            {callToAction?.enable &&
              renderContactUsSection(rawPublicacion?.length, key)}
            <div className="mision container col-11 col-lg-10 mx-auto my-5 pt-3">
              <h3 className="mision-h3">{title}</h3>
              <div className="description my-5 col-10 mx-auto">
                <BlockText data={publicacion[key]?._rawDescription} />
              </div>
              <div className="galeria">
                <GrupoImagenesPrincipal
                  rawGaleria={publicacion[key]?._rawGaleria}
                  rawImages={publicacion[key]?._rawGaleria?.images}
                  objetoImagenes={publicacion[key]?.galeria}
                />
              </div>
            </div>
            {callToAction?.enable && renderCta(rawPublicacion?.length)}
          </>
        )
      })}
    </div>
  )
}
export default ContentMain
